import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavBarTwo from "../navbar/NavBarTwo";
import Footer from "../footer/Footer";
import axios from "axios";


function DriveFormTwo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData: initialData } = location.state || {};


  const openTermsAndConditions = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.open("/terms-and-conditions", "_blank", "noopener,noreferrer");
  };

  const openPrivacyPolicy = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.open("/privacy-policy", "_blank", "noopener,noreferrer");
  };


  const [formData, setFormData] = useState({
    ...initialData,
    vehicle_type: "",
    work_area: "",
    psv_photo: "",
    id_photo: "",
    license_photo: "",
    good_conduct_photo: "",
  });

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const vehicleResponse = await axios.get('http://127.0.0.1:8000/api/vehicle-types/');
        const vehicleResponse = await axios.get("https://derebackend-1.onrender.com/api/vehicle-types/");
        setVehicleTypes(vehicleResponse.data);

        // const workAreasResponse = await axios.get('http://127.0.0.1:8000/api/work-areas/');
        const workAreasResponse = await axios.get("https://derebackend-1.onrender.com/api/work-areas/");
        setWorkAreas(workAreasResponse.data);
      } catch (error) {
        console.error("Error fetching vehicle types or work areas:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "vehicle_type" || name === "work_area") {
      setFormData({ ...formData, [name]: parseInt(value, 10) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (validTypes.includes(file.type)) {
        setFormData({ ...formData, [name]: file });
      } else {
        alert("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    // Validation: Check if all required fields are filled
    if (!formData.vehicle_type || !formData.work_area || !formData.psv_photo || !formData.id_photo || !formData.license_photo || !formData.good_conduct_photo) {
      let errors = [];
      if (!formData.vehicle_type) errors.push("Please select a vehicle type.");
      if (!formData.work_area) errors.push("Please select a work area.");
      if (!formData.psv_photo) errors.push("Please upload your PSV photo.");
      if (!formData.id_photo) errors.push("Please upload your ID photo.");
      if (!formData.license_photo) errors.push("Please upload your license photo.");
      if (!formData.good_conduct_photo) errors.push("Please upload your Good Conduct photo.");

      setErrorMessage(errors.join(" "));
      setIsSubmitting(false);
      return;
    }

    const submissionData = new FormData();
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== "") {
        submissionData.append(key, formData[key]);
      }
    }

    try {
      // const response = await axios.post('http://127.0.0.1:8000/api/drivers/', submissionData, {
      const response = await axios.post("https://derebackend-1.onrender.com/api/drivers/", submissionData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
      );

      if (response.status === 201) {
        navigate('/jobseeker', {
          state: {
            successPopup: "Account created successfully!",
            formData: {
              first_name: formData.first_name,
              email: formData.email
            }
          },
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.non_field_errors) {
            setErrorMessage(error.response.data.non_field_errors.join(' ')); 
          } else if (error.response.data && typeof error.response.data === 'object') {
            const errorMessages = Object.values(error.response.data).flat().join(' ');
            setErrorMessage(errorMessages); 
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen bg-cover pt-2 "
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      {/* Navbar */}
      <NavBarTwo />

      <div className=" flex-grow flex flex-col items-center mb-8">
        <div className="flex-grow w-full flex justify-center items-start xl:px-32 px-8 mt-8">
          <div
            className="md:flex bg-white bg-opacity-50 rounded-xl shadow-lg w-full"
            style={{ height: "95%" }}
          >
            {/* Step Two Section */}
            <div className="bg-gray-200 p-5 md:w-1/3 flex flex-col justify-center md:rounded-xl shadow-lg">
              <div className="text-center">
                <h2 className="md:text-2xl xl:text-4xl font-bold font-serif text-black">
                  Step Two
                </h2>
                <h2 className="md:text-2xl   xl:text-3xl font-bold font-serif text-black">
                  Driver Information
                </h2>
                <p className="text-black mt-2 text-center font-serif xl:text-xl text-xs md:text-lg">
                  This is information pertaining your
                  <br /> driver expertise
                </p>
              </div>
            </div>

            {/* Form Section */}
            <div className="bg-white bg-opacity-50 p-2 md:p-5 md:w-2/3 flex flex-col md:rounded-r-xl">
              <form
                className="space-y-4 md:space-y-6 mt-4 md:mt-8"
                onSubmit={handleSubmit}
              >
                {/* Error Message popup */}
                {errorMessage && (
                  <div className="flex justify-center items-center">
                    <div className="error-alert bg-red-500 text-white p-2 text-xs font-serif md:w-1/2">
                      {errorMessage}
                    </div>
                  </div>
                )}

                {/* First Line */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:mt-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="vehicleLicense"
                      className="md:ml-14 mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif whitespace-nowrap"
                    >
                      Which Vehicle Are You Licensed to Drive<span className="text-red-500 ml-1">*</span>
                    </label>

                    {/* <label htmlFor="vehicleLicense" className="md:ml-14 mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">Which Vehicle Are You Licensed to Drive</label> */}
                    <select
                      id="vehicleLicense"
                      name="vehicle_type"
                      className="md:ml-14 p-2 text-sm xl:text-lg font-serif md:text-xs border border-gray-300 rounded-lg"
                      value={formData.vehicle_type}
                      onChange={handleChange}
                      required
                    > <option value="">Select</option>
                      {vehicleTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="workArea"
                      className="md:ml-14 mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif"
                    >
                      Work Area<span className="text-red-500 ml-1">*</span>
                    </label>
                    <select
                      id="workArea"
                      name="work_area"
                      className="md:ml-14 p-2 text-sm xl:text-lg font-serif md:text-xs border border-gray-300 rounded-lg"
                      value={formData.work_area}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      {workAreas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Second Line */}
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="idPhoto"
                      className="md:ml-14 mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif"
                    >
                      ID Photo <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="idPhoto"
                        className="md:ml-14 bg-gray-500 shadow-md border border-gray-500 px-3 py-1 md:px-6 md:py-3 text-white text-xs xl:text-sm md:text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg "
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="idPhoto"
                          name="id_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}

                        />
                      </label>
                      <span className="ml-2 text-black text-xs">
                        {formData.id_photo
                          ? formData.id_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="psvPhoto"
                      className="md:ml-14 mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif"
                    >
                      PSV Photo <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="psvPhoto"
                        className="md:ml-14 bg-gray-500 shadow-md border border-gray-500 px-3 py-1 md:px-6 md:py-3 text-white text-xs xl:text-sm font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="psvPhoto"
                          name="psv_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}

                        />
                      </label>
                      <span className="ml-2 text-black text-xs md:text-xs">
                        {formData.psv_photo
                          ? formData.psv_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Third Line */}
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="licensePhoto"
                      className="md:ml-14 mb-1 text-black text-left uppercase text-xs xl:text-lg font-serif"
                    >
                      License Photo <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="licensePhoto"
                        className="md:ml-14 bg-gray-500 shadow-md border border-gray-500 px-3 py-1 md:px-6 md:py-3 text-white text-xs xl:text-sm font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="licensePhoto"
                          name="license_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}

                        />
                      </label>
                      <span className="ml-2 text-black text-xs">
                        {formData.license_photo
                          ? formData.license_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="conductPhoto"
                      className="mb-1 md:ml-14 text-black text-left uppercase text-xs xl:text-lg font-serif"
                    >
                      Good Conduct <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex items-center">
                      <label
                        htmlFor="conductPhoto"
                        className=" md:ml-14 bg-gray-500 rounded-lg shadow-md border border-gray-500 px-3 py-1 md:px-6 md:py-3 text-white text-xs xl:text-sm font-serif cursor-pointer hover:bg-gray-400"
                      >
                        CHOOSE FILE
                        <input
                          type="file"
                          id="conductPhoto"
                          name="good_conduct_photo"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileChange}

                        />
                      </label>
                      <span className="ml-2 text-black text-xs md:text-xs">
                        {formData.good_conduct_photo
                          ? formData.good_conduct_photo.name
                          : "NO FILE CHOSEN"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  {/* Terms and Conditions */}
                  <div className="flex md:items-center space-x-2 md:mt-8 md:ml-14">
                    <input
                      type="checkbox"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      className="h-4 w-4 text-orange-700 border-gray-300 rounded focus:ring-orange-600"
                      required
                    />
               <label htmlFor="termsAndConditions" className="font-serif text-left text-xs xl:text-lg uppercase">
  I AGREE TO THE 
  <a 
    href="/terms-and-conditions" 
    className="text-orange-600 hover:underline ml-1 mr-1"
    onClick={openTermsAndConditions}
  >
    TERMS AND CONDITIONS
  </a> 
  AND 
  <a 
    href="/privacy-policy" 
    className="text-orange-600 hover:underline ml-1"
    onClick={openPrivacyPolicy}
  >
    PRIVACY POLICY
  </a>
</label>

                  </div>

                  {/* Create Account Button */}
                  <div className="flex justify-end mt-4">
                    <button
                      type="submit"
                      className="bg-orange-700 text-white py-2 px-7 rounded-lg hover:bg-orange-800 text-xs xl:text-lg font-serif xl:mb-12"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Creating Account ..." : "Create Account"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DriveFormTwo;
