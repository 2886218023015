import React, { useEffect, useState } from "react";
import NavBarThree from "../navbar/NavBarThree";
import Footer from "../footer/Footer";
import api from "../../services/api";
import Spinner from "../spinner/Spinner";
import { FiLink } from "react-icons/fi";


function PartnerDashboard() {
  const [profile, setProfile] = useState({});
  const [workAreas, setWorkAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [activeSection, setActiveSection] = useState("postedJobs");
  const [closingJobId, setClosingJobId] = useState(null);
  // const [jobsPosted, setJobsPosted] = useState([]);

  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [jobApplicants, setJobApplicants] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // const [isRemoving, setIsRemoving] = useState(false);
  const [removingApplicationId, setRemovingApplicationId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedProfile = localStorage.getItem("profile");
        const cachedWorkAreas = localStorage.getItem("workAreas");
        const cachedJobs = localStorage.getItem("jobs");
        const cachedVehicleMakes = localStorage.getItem("vehicleMakes"); //
        const cachedVehicleModels = localStorage.getItem("vehicleModels");
        const cachedVehicleTypes = localStorage.getItem("vehicleTypes");

        console.log("Cached Jobs:", cachedJobs);

        if (cachedProfile && cachedWorkAreas && cachedJobs) {
          setProfile(JSON.parse(cachedProfile));
          setWorkAreas(JSON.parse(cachedWorkAreas));
          setJobData(JSON.parse(cachedJobs));
          setVehicleMakes(JSON.parse(cachedVehicleMakes));
          setVehicleModels(JSON.parse(cachedVehicleModels));
          setVehicleTypes(JSON.parse(cachedVehicleTypes));
        }

        // Fetch from API to ensure data is up-to-date
        const profileData = await api.get("/api/users/me/");
        const workAreasData = await api.get("/api/work-areas/");
        const jobData = await api.get("/api/jobs/");
        const vehicleModelsData = await api.get("/api/vehicle-models/");
        const vehicleMakesData = await api.get("/api/vehicle-makes/");
        const vehicleTypesData = await api.get("/api/vehicle-types/");

        localStorage.setItem("profile", JSON.stringify(profileData));
        localStorage.setItem("workAreas", JSON.stringify(workAreasData));
        localStorage.setItem("jobs", JSON.stringify(jobData));
        localStorage.setItem("vehicleMakes", JSON.stringify(vehicleModelsData));
        localStorage.setItem("vehicleModels", JSON.stringify(vehicleMakesData));
        localStorage.setItem("vehicleTypes", JSON.stringify(vehicleTypesData));

        setProfile(profileData);
        setWorkAreas(workAreasData);
        setJobData(jobData);
        setVehicleMakes(vehicleMakesData);
        setVehicleModels(vehicleModelsData);
        setVehicleTypes(vehicleTypesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Applicants
  useEffect(() => {
    const fetchJobApplicants = async () => {
      try {
        const response = await api.get("/api/applications/"); 
        console.log("Full API Response: ", response);

        const applicants = response;

        if (Array.isArray(applicants)) {
          setJobApplicants(applicants);
          console.log("Job Applicants: ", applicants);
        } else {
          console.error("API Response is not an array:", applicants);
        }
      } catch (error) {
        console.error("Error fetching job applicants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobApplicants();
  }, []);

  const getWorkAreaName = (id) => {
    const workArea = workAreas.find((area) => area.id === id);
    return workArea ? workArea.name : "Unknown";
  };
  const getVehicleMakeName = (makeId) => {
    const make = vehicleMakes.find((item) => item.id === makeId);
    return make ? make.name : "Unknown Make";
  };

  const getVehicleModelName = (modelId) => {
    const model = vehicleModels.find((item) => item.id === modelId);
    return model ? model.name : "Unknown Model";
  };

  const getVehicleTypeName = (id) => {
    const vehicleType = vehicleTypes.find((type) => type.id === id);
    return vehicleType ? vehicleType.name : "Unknown";
  };

  const handleRemove = async (applicationId) => {
    setRemovingApplicationId(applicationId);
    try {
      await api.post(`/api/applications/${applicationId}/remove_by_partner/`);
      console.log("Application removed successfully.");
      // Update status to "Rejected"
      const updatedApplicants = jobApplicants.map((applicant) =>
        applicant.id === applicationId
          ? { ...applicant, status: "Rejected" }
          : applicant
      );
      setJobApplicants(updatedApplicants);

      // Refetch the applicants list if needed
      const response = await api.get("/api/applications/");
      setJobApplicants(response);
    } catch (error) {
      console.error("Error removing application:", error);
    } finally {
      setRemovingApplicationId(null);
    }
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const handleCloseJob = async (event, jobId) => {
    event.preventDefault(); 
    setClosingJobId(jobId);
  
    try {
      await api.post(`/api/jobs/${jobId}/close_job/`);
      const updatedJobData = jobData.filter((job) => job.id !== jobId);
      setJobData(updatedJobData);
      setActiveSection("postedJobs");
  
      
      alert("Job closed successfully.");
    } catch (error) {
      console.error("Error closing job:", error);
      
      alert("Failed to close job. Please try again.");
    } finally {
      setClosingJobId(null);
    }
  };

  
  return (
    <div
      className="flex flex-col min-h-screen bg-cover max-w-full"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      <NavBarThree />
      <div className="flex flex-col flex-grow w-full items-center justify-center">
        <div className="bg-white relative bg-opacity-50 rounded-xl mt-3 shadow-lg px-2  md:px-4 m-4 md:mx-8 w-[90%]">
          <div className="text-center absolute -top-4 left-1/2 transform -translate-x-1/2">
            <img
              src="/assets/profile2.png"
              alt="Welcome"
              className="mx-auto mb-1 w-[24%] md:w-[8%] h-auto"
            />
          </div>
          <div className="mt-12 xl:mt-20">
            <p className="xl:text-xl md:text-sm text-xs">
              <span className="font-semibold"></span>{" "}
              {profile.role
                ? profile.role.charAt(0).toUpperCase() + profile.role.slice(1)
                : "Loading..."}
            </p>
            <h2 className="text-sm md:text-xl xl:text-2xl font-bold font-serif text-black mb-2">
              {loading ? (
                <Spinner />
              ) : (
                <div>
                  {profile.first_name
                    ? `${profile.first_name} ${profile.last_name}`
                    : "No Data Available"}
                </div>
              )}
            </h2>
          </div>
          {/* Toggle Buttons */}
          <div className="flex md:space-x-8 space-x-2 items-center justify-center mt-3">
            <button
              className={`py-2 md:px-4 px-2 text-xs md:text-sm  xl:text-xl font-bold font-serif rounded-t  ${
                activeSection === "postedJobs"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("postedJobs")}
            >
              JOBS POSTED
            </button>

            <button
              className={`py-2 px-4 text-xs md:text-sm  xl:text-xl font-bold font-serif rounded-t  ${
                activeSection === "applicants"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("applicants")}
            >
              APPLICANTS
            </button>
            <button
              className={`py-2 md:px-4 px-2 text-xs md:text-sm xl:text-xl font-bold font-serif rounded-t ${
                activeSection === "profile"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("profile")}
            >
              MY PROFILE
            </button>
          </div>
        </div>

        {/* Conditional Rendering of Sections */}
        {activeSection === "profile" && (
          <div className="bg-white bg-opacity-50 md:p-2 p-2 flex flex-col rounded-xl w-[90%]">
            <div className="space-y-6 md:px-10 md:py-6">
              <div className="grid grid-cols-2  gap-2 md:gap-8">
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif"
                  >
                    Name
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {profile.first_name} {profile.last_name}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="email"
                    className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                  >
                    Email
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {profile.email}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 md:gap-8">
                <div className="flex flex-col">
                  <label
                    htmlFor="workArea"
                    className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                  >
                    Location
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {getWorkAreaName(
                      profile.partner_details.preferred_work_area
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="idNumber"
                    className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                  >
                    ID NUMBER
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {profile.partner_details.id_number}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 md:gap-8">
                <div className="flex flex-col">
                  <label
                    htmlFor="companyName"
                    className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                  >
                    COMPANY NAME
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {profile.partner_details.company_name}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="companyNumber"
                    className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                  >
                    COMPANY NUMBER
                  </label>
                  <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                    {profile.partner_details.company_number}
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-orange-700 text-white py-1.5 px-7 rounded-lg mt-12 mb-5 hover:bg-orange-800 text-xs xl:text-lg font-serif"
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        )}

        {activeSection === "postedJobs" && (
          <div className="bg-white bg-opacity-60 rounded-xl  mb-8 mt-2 shadow-lg py-4 h-[80vh] md:h-[60vh] w-[90%]  flex flex-col items-start justify-start overflow-y-auto  custom-scrollbar">
            {jobData && Array.isArray(jobData) && jobData.length > 0 ? (
              jobData.map((job) => (
                <a
                  key={job.id}
                  href={`/editjob/${job.id}`}
                  className="flex flex-col md:flex-row justify-center items-center xl:p-5 bg-gray-300 w-[90%] mt-4 mx-4 rounded-xl"
                >
                  <div className="md:w-2/3 flex flex-col items-start p-2">
                    <div className="bg-gray-300 w-full p-2 flex flex-col space-y-2 items-start rounded-xl relative">
                      <p className="text-sm xl:text-xl font-semibold font-serif mr-2">
                        Job ID:{" "}
                        <span className="text-red-500 mr-2">
                          {job.id || "Unknown ID"}
                        </span>
                        <span className="w-3 h-3 bg-green-500 rounded-full inline-block mr-2"></span>
                      </p>
                      <p className="font-serif text-xs xl:text-lg font-semibold mt-1 underline">
                        Job Summary
                      </p>
                      <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                        <span className="font-bold mr-2">Work Area:</span>{" "}
                        {getWorkAreaName(job.preferred_work_area)}
                      </div>
                      <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                        <span className="font-bold mr-2">Vehicle Make:</span>{" "}
                        {getVehicleMakeName(job.vehicle_make)}
                      </div>
                      <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                        <span className="font-bold mr-2">Vehicle Model:</span>{" "}
                        {getVehicleModelName(job.vehicle_model)}
                      </div>
                      <div className="text-xs flex-col xl:text-lg text-left font-serif mr-2 flex">
                        <span className="font-bold mr-2">Requirements:</span>{" "}
                        {job.requirements}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col m-4 md:items-end md:justify-end p-2 w-full  mt-1 md:w-1/3">
                    <div className="p-2 bg-gray-400 rounded-lg relative md:w-[60%] w-full">
                      <img
                        src={job.vehicle_photo || "/assets/default-car.png"}
                        alt="Vehicle"
                        className="w-[100%] h-auto xl:w-[250px] xl:h-[250px] md:w-[180px] md:h-[180px] rounded-lg object-cover"
                      />
                    </div>
                    <button
                   className="font-serif text-white py-1 xl:text-lg px-2 bg-orange-700 rounded-lg mx-4 text-xs mt-3 cursor-pointer"
                   
                      onClick={(event) => handleCloseJob(event, job.id)}
                      disabled={closingJobId === job.id}
                    >
                      {closingJobId === job.id ? "Closing..." : "Close Job"}
                    </button>
                  </div>
                </a>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[80%]">
                {loading ? (
                  <Spinner />
                ) : (
                  <p className="text-black text-lg xl:text-2xl font-serif font-bold">
                    You currently have no jobs posted.
                  </p>
                )}
              </div>
            )}
          </div>
        )}

        {activeSection === "applicants" && (
          <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-2 shadow-lg py-4 h-[80vh] md:h-[60vh] w-[90%] flex flex-col items-start justify-start overflow-y-auto custom-scrollbar">
            {/* Applicants */}
            {jobApplicants.length > 0 ? (
              jobApplicants.map((jobApplicant) => (
                <div
                  key={`${jobApplicant.id}-${jobApplicant.driver.job_id}`}
                  className="flex flex-col md:flex-row md:justify-center md:items-center p-4 md:py-4 xl:p-5 bg-gray-300 w-[90%] mt-4 mx-4 rounded-xl"
                >
                  <div className="xl:gap-6 md:gap-4 md:w-[40%]">
                    <div className="bg-gray-300 w-[90%] py-1 px-1 md:px-6 flex flex-col items-start justify-start rounded-xl gap-1 relative">
                      <p className="md:text-xl text-sm xl:text-2xl text-left font-semibold font-serif mr-2">
                        JOB ID:{" "}
                        <span className="text-red-500">
                          {jobApplicant.job.id}
                        </span>
                      </p>
                      <p className="text-xs xl:text-xl font-semibold font-serif mr-2">
                        Name:{" "}
                        <span className="font-thin">
                          {jobApplicant.driver.first_name}{" "}
                          {jobApplicant.driver.last_name}
                        </span>
                      </p>
                      <p className="text-xs xl:text-xl font-semibold font-serif mr-2">
                        Email:{" "}
                        <span className="font-thin">
                          {jobApplicant.driver.email}
                        </span>
                      </p>
                      <p className="text-xs xl:text-xl font-semibold font-serif mr-2">
                        Phone No:{" "}
                        <span className="font-thin">
                          {jobApplicant.driver.mobile_number}
                        </span>
                      </p>
                      <p className="text-xs xl:text-xl text-left font-semibold font-serif mr-2">
                        Vehicle Licence:{" "}
                        <span className="font-thin">
                          {getVehicleTypeName(jobApplicant.driver.vehicle_type)}
                        </span>
                      </p>
                      <p className="text-xs xl:text-xl text-left font-semibold font-serif mr-2">
                        Work Area:{" "}
                        <span className="font-thin">
                          {getWorkAreaName(jobApplicant.driver.work_area)}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start py-2 mt-1 md:w-[40%]">
                    <p className="font-serif font-semibold text-sm xl:text-lg underline mb-2">
                      APPLICANT DOCUMENTATION
                    </p>
                    <p className="font-serif font-thin xl:text-lg text-xs mb-2">
                      Click link to download:
                    </p>
                    <div className="flex">
                      <span className="text-orange-700 mr-2">
                        <FiLink size={12} />
                      </span>
                      <a
                        href={jobApplicant.driver.id_photo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-700 text-xs xl:text-lg font-semibold font-serif mr-2"
                      >
                        ID Card
                      </a>
                    </div>
                    <div className="flex">
                      <span className="text-orange-700 mr-2">
                        <FiLink size={12} />
                      </span>
                      <a
                        href={jobApplicant.driver.psv_photo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-700 text-xs xl:text-lg font-semibold font-serif mr-2"
                      >
                        PSV PHOTO
                      </a>
                    </div>
                    <div className="flex">
                      <span className="text-orange-700 mr-2">
                        <FiLink size={12} />
                      </span>
                      <a
                        href={jobApplicant.driver.license_photo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-700 text-xs xl:text-lg font-semibold font-serif mr-2"
                      >
                        LICENCE PHOTO
                      </a>
                    </div>
                    <div className="flex">
                      <span className="text-orange-700 mr-2">
                        <FiLink size={12} />
                      </span>
                      <a
                        href={jobApplicant.driver.good_conduct_photo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-700 text-xs xl:text-lg font-semibold font-serif mr-2"
                      >
                        CERTIFICATE OF GOOD CONDUCT
                      </a>
                    </div>
                  </div>
                  <div className="md:w-[20%] flex flex-col items-baseline md:mt-20 xl:mt-24 mb-5 md:mb-0 justify-end">
                    <button
                      className="px-7 py-1 bg-orange-700 text-xs text-white rounded-lg hover:bg-orange-800 transition duration-300"
                      onClick={() => handleRemove(jobApplicant.id)}
                      disabled={removingApplicationId === jobApplicant.id}
                    >
                      {removingApplicationId === jobApplicant.id
                        ? "Removing..."
                        : "Remove Applicant"}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[80%]">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <p className="text-black text-lg xl:text-2xl font-serif font-bold">
                    No job applicants.
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PartnerDashboard;
