import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import NavBarThree from "../navbar/NavBarThree";
import Footer from "../footer/Footer";
import api from "../../services/api";
import Spinner from "../spinner/Spinner";

function DriverDashboard() {
  const [driverData, setDriverData] = useState(null);
  const [workAreas, setWorkAreas] = useState([]);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("applications");
  const [jobApplicants, setJobApplicants] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const location = useLocation();
  const [formData, setFormData] = useState({
    id_photo: null,
    psv_photo: null,
    license_photo: null,
    good_conduct_photo: null,
  });

  // const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [withdrawingApplicationId, setWithdrawingApplicationId] =
    useState(null);

    // For picking profile section when someone clicks update profile link when applying for job
  useEffect(() => {
    if (location.state?.activeSection) {
      setActiveSection(location.state.activeSection);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const data = await api.get("/api/users/me/");
        setDriverData(data);

        const workAreasData = await api.get("/api/work-areas/");
        setWorkAreas(workAreasData);

        const vehicleTypesData = await api.get("/api/vehicle-types/");
        setVehicleTypes(vehicleTypesData);

        const vehicleMakesData = await api.get("/api/vehicle-makes/");
        setVehicleMakes(vehicleMakesData);

        const vehicleModelsData = await api.get("/api/vehicle-models/");
        setVehicleModels(vehicleModelsData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching driver data:", error);
        setLoading(false);
      }
    };

    fetchDriverData();
  }, []);

  // Applications
  useEffect(() => {
    const fetchJobApplicants = async () => {
      try {
        const response = await api.get("/api/applications/");
        console.log("Full API Response: ", response);

        const applicants = response;

        if (Array.isArray(applicants)) {
          setJobApplicants(applicants);
          console.log("Job Applicants: ", applicants);
        } else {
          console.error("API Response is not an array:", applicants);
        }
      } catch (error) {
        console.error("Error fetching job applicants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobApplicants();
  }, []);



  const getWorkAreaName = (id) => {
    const workArea = workAreas.find((area) => area.id === id);
    return workArea ? workArea.name : "Unknown";
  };

  const getVehicleTypeName = (id) => {
    const vehicleType = vehicleTypes.find((type) => type.id === id);
    return vehicleType ? vehicleType.name : "Unknown";
  };

  const getVehicleMakeName = (makeId) => {
    const make = vehicleMakes.find((item) => item.id === makeId);
    return make ? make.name : "Unknown Make";
  };

  const getVehicleModelName = (modelId) => {
    const model = vehicleModels.find((item) => item.id === modelId);
    return model ? model.name : "Unknown Model";
  };

  const handleWithdraw = async (applicationId) => {
    setWithdrawingApplicationId(applicationId);
    try {
      await api.post(`/api/applications/${applicationId}/withdraw_by_driver/`);
      console.log("Application withdrawn successfully.");
      const response = await api.get("/api/applications/");
      setJobApplicants(response);
    } catch (error) {
      console.error("Error withdrawing application:", error);
    } finally {
      setWithdrawingApplicationId(null);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  // if (!driverData) {
  //   return <div>Error loading data.</div>;
  // }

  return (
    <div
      className="flex flex-col min-h-screen bg-cover max-w-full"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      <NavBarThree />

      <div className="flex flex-col flex-grow w-full items-center justify-center">
        <div className="bg-white relative bg-opacity-50 rounded-xl mt-6 shadow-lg px-2 md:px-4 m-4 md:mx-8 w-[90%]">
          <div className="text-center absolute -top-4 left-1/2 transform -translate-x-1/2">
            <img
              src="/assets/profile2.png"
              alt="Profile"
              className="mx-auto mb-1 w-[24%] md:w-[8%] h-auto"
            />
          </div>
          <div className="mt-12 xl:mt-16">
            <p className="xl:text-xl md:text-sm text-xs">
              <span className="font-semibold font-serif mb-1"></span>
              {driverData?.role
                ? driverData.role.charAt(0).toUpperCase() +
                driverData.role.slice(1)
                : "Loading..."}
            </p>
            <h2 className="text-sm md:text-xl xl:text-2xl font-bold font-serif text-black mb-1">
              {loading ? (
                <Spinner />
              ) : (
                `${driverData?.first_name} ${driverData?.last_name}`
              )}
            </h2>

            <div className="flex md:space-x-16 space-x-4 items-center justify-center mt-3">
              <button
                className={`py-2 px-4 text-xs md:text-sm font-bold font-serif rounded-t ${activeSection === "applications"
                    ? "bg-orange-700 text-white"
                    : "bg-gray-300 text-black"
                  }`}
                onClick={() => setActiveSection("applications")}
              >
                APPLICATIONS
              </button>
              <button
                className={`py-2 md:px-6 px-4 text-xs md:text-sm font-bold font-serif rounded-t ${activeSection === "profile"
                    ? "bg-orange-700 text-white"
                    : "bg-gray-300 text-black"
                  }`}
                onClick={() => setActiveSection("profile")}
              >
                MY PROFILE
              </button>
            </div>
          </div>
        </div>

        {activeSection === "profile" && (
          <div className="bg-white bg-opacity-50 md:p-5 p-2 flex flex-col rounded-xl w-[90%] ">
            {/* Parent Flex Container for Profile Details and File Uploads */}
            <div className="mt-4 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-2 md:px-2 xl:py-6">
              {/* Left Column: Profile Details */}
              <div className="flex-1 space-y-4">
                {/* Existing Profile Details Grids */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif"
                    >
                      First Name
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.first_name}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="password"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      LAST NAME
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.last_name}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Email
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.email}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="password"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      MOBILE NUMBER
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.driver_details.mobile_number}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="dateOfBirth"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      DATE OF BIRTH
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.driver_details.date_of_birth}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="workLocation"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      ID NUMBER
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {driverData.driver_details.id_number}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="workLocation"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      WORK LOCATION
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {getWorkAreaName(driverData.driver_details.work_area)}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="vehicleType"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif "
                    >
                      Licensed to Drive
                    </label>
                    <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                      {getVehicleTypeName(
                        driverData.driver_details.vehicle_type
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column: File Upload Sections */}
              <div className=" flex-2 space-y-6">
                {/* ID Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="idPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    ID Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="idPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="idPhoto"
                        name="id_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.id_photo ? (
                        formData.id_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.id_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700  underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* PSV Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="psvPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    PSV Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="psvPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="psvPhoto"
                        name="psv_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.psv_photo ? (
                        formData.psv_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.psv_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* License Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="licensePhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    License Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="licensePhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="licensePhoto"
                        name="license_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.license_photo ? (
                        formData.license_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.license_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* Good Conduct */}
                <div className="flex flex-col">
                  <label
                    htmlFor="conductPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    Good Conduct
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="conductPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="conductPhoto"
                        name="good_conduct_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.good_conduct_photo ? (
                        formData.good_conduct_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.good_conduct_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700  underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Update Profile Button */}
            <div className="flex justify-center">
              <button
                type="button"
                className="
      bg-orange-700 
      text-white 
      py-2 
      px-8 
      rounded-lg 
      mt-12 
      mr-8
      mb-5 
      hover:bg-orange-800 
      text-sm 
      xl:text-lg 
      font-serif 
      w-48 sm:w-64 md:w-80     
      lg:w-96     
    "
              >
                Update Profile
              </button>
            </div>
          </div>
        )}

        {activeSection === "applications" && (
          <div className="bg-white bg-opacity-60 h-[80vh] md:h-[60vh] rounded-xl mb-8 shadow-lg py-4 flex flex-col items-start justify-start px-2 w-[90%] overflow-y-auto custom-scrollbar">
            {jobApplicants.length > 0 ? (
              jobApplicants.map((jobApplicant) => (
                <div
                  key={jobApplicant.id}
                  className="flex flex-col sm:flex-row justify-center items-center p-4 bg-gray-300 w-full sm:w-[90%] mt-4 mx-auto rounded-xl"
                >
                  {/* Job Details */}
                  <div className="w-full sm:w-1/2">
                    <div className="bg-gray-300 w-full p-4 flex flex-col items-start rounded-xl gap-2">
                      <p className="text-sm xl:text-xl font-semibold font-serif mr-2">
                        JOB ID:{" "}
                        <span className="text-orange-500">
                          {jobApplicant.job.id}
                        </span>
                      </p>

                      {/* Status Display */}
                      <div className="flex items-center">
                        {jobApplicant.status === "active" ? (
                          <>
                            <span className="w-3 h-3 bg-green-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-green-500">
                              Active
                            </p>
                          </>
                        ) : jobApplicant.status === "rejected" ? (
                          <>
                            <span className="w-3 h-3 bg-red-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-red-500">
                              Rejected
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="w-3 h-3 bg-gray-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-gray-500">
                              Unknown
                            </p>
                          </>
                        )}
                      </div>

                      <h2 className="text-xs xl:text-xl font-serif mt-2">
                        JOB DETAILS
                      </h2>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Vehicle Make:</strong>{" "}
                        {getVehicleMakeName(jobApplicant.job.vehicle_make)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Vehicle Model:</strong>{" "}
                        {getVehicleModelName(jobApplicant.job.vehicle_model)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Location:</strong>{" "}
                        {getWorkAreaName(jobApplicant.job.preferred_work_area)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1 text-left">
                        <strong>Requirements:</strong>{" "}
                        {jobApplicant.job.requirements}
                      </p>
                    </div>
                  </div>

                  {/* Right section - Withdraw button */}
                  <div className="flex flex-col items-left sm:items-end py-2 mt-4 w-full sm:w-1/2">
                    <div className="w-auto bg-gray-400 rounded-lg p-2">
                      <div className="bg-white rounded-lg p-2">
                        <img
                          src={jobApplicant.job.vehicle_photo}
                          alt="Car"
                          className="w-40 h-auto mx-auto"
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <button
                        className="px-7 py-1 bg-orange-700 text-xs text-white rounded-lg hover:bg-orange-800 transition duration-300"
                        onClick={() => handleWithdraw(jobApplicant.id)}
                        disabled={withdrawingApplicationId === jobApplicant.id}
                      >
                        {withdrawingApplicationId === jobApplicant.id
                          ? "Withdrawing..."
                          : "Withdraw"}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[80%]">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <p className="text-black text-lg xl:text-2xl font-serif font-bold">
                    No job applications.
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default DriverDashboard;
