// import React from 'react';

// const Spinner = () => {
//   return (
//     <div className="flex justify-center items-center">
//       <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-blue-800 mt-10 border-solid"></div>
//     </div>
//   );
// };

// export default Spinner;


import React from 'react';

const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="relative flex justify-center items-center h-16 w-16 animate-spin">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className="absolute w-2 h-6 bg-orange-600 rounded-sm"
            style={{
              transform: `rotate(${i * 30}deg) translate(0, -24px)`,
              opacity: `${1 - i * 0.08}`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Spinner;

